<script setup lang="ts">
import VueApexCharts from 'vue3-apexcharts'
import useFormatterPrices from '@/composables/formatter-prices.js'

defineProps<{ salesRevenue: number, revenueSeries: { name: string, data: number[] }[] }>()
const store = useAppStore()

// revenue
const revenueChart = computed(() => {
  const isDark = store.theme === 'dark' || store.isDarkMode ? true : false
  const isRtl = store.rtlClass === 'rtl' ? true : false
  return {
    chart: {
      height: 325,
      type: 'area',
      fontFamily: 'Poppins, sans-serif',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      width: 2,
      lineCap: 'square',
    },
    dropShadow: {
      enabled: true,
      opacity: 0.2,
      blur: 10,
      left: -7,
      top: 22,
    },
    colors: isDark ? ['#2196f3', '#e7515a'] : ['#1b55e2', '#e7515a'],
    markers: {
      discrete: [
        {
          seriesIndex: 0,
          dataPointIndex: 6,
          fillColor: '#1b55e2',
          strokeColor: 'transparent',
          size: 7,
        },
        {
          seriesIndex: 1,
          dataPointIndex: 5,
          fillColor: '#e7515a',
          strokeColor: 'transparent',
          size: 7,
        },
      ],
    },
    labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: true,
      },
      labels: {
        offsetX: isRtl ? 2 : 0,
        offsetY: 5,
        style: {
          fontSize: '12px',
          cssClass: 'apexcharts-xaxis-title',
        },
      },
    },
    yaxis: {
      tickAmount: 7,
      labels: {
        formatter: (value: number) => {
          return `${useFormatterPrices(value)}`
        },
        offsetX: isRtl ? -30 : -10,
        offsetY: 0,
        style: {
          fontSize: '12px',
          cssClass: 'apexcharts-yaxis-title',
        },
      },
      opposite: isRtl ? true : false,
    },
    grid: {
      borderColor: isDark ? '#191e3a' : '#e0e6ed',
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      fontSize: '16px',
      markers: {
        width: 10,
        height: 10,
        offsetX: -2,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: isDark ? 0.19 : 0.28,
        opacityTo: 0.05,
        stops: isDark ? [100, 100] : [45, 100],
      },
    },
  }
})
</script>

<template>
  <div class="panel h-full">
    <div class="mb-5 flex items-center justify-between dark:text-white-light">
      <h5 class="text-lg font-semibold">
        Análisis de ingresos
      </h5>
    </div>
    <p class="text-lg dark:text-white-light/90">
      Ingresos por ventas
      <span class="ml-2 text-primary">{{ useFormatterPrices(salesRevenue) }}</span>
    </p>
    <VueApexCharts
      height="325"
      :options="revenueChart"
      :series="revenueSeries"
      class="overflow-hidden rounded-lg bg-white dark:bg-black"
    >
      <!-- loader -->
      <div class="grid min-h-[325px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
        <span
          class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"
        />
      </div>
    </VueApexCharts>
  </div>
</template>
