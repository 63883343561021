<script setup lang="ts">
import type { Sale } from '@/types/graphics.js'
import useFormatterPrices from '@/composables/formatter-prices.js'
import VueApexCharts from 'vue3-apexcharts'

const props = defineProps<{
  salesData: Sale[]
  salesByCategorySeries: number[]
  monthRef: number
  allSalesAreZero: boolean
}>()

const emits = defineEmits(['handleLoadSalesPerMonth'])

const store = useAppStore()

const selectedOption = ref(new Date().getMonth() + 1)

const options = ref([
  { value: 1, label: 'Ene' },
  { value: 2, label: 'Feb' },
  { value: 3, label: 'Mar' },
  { value: 4, label: 'Abr' },
  { value: 5, label: 'May' },
  { value: 6, label: 'Jun' },
  { value: 7, label: 'Jul' },
  { value: 8, label: 'Ago' },
  { value: 9, label: 'Sep' },
  { value: 10, label: 'Oct' },
  { value: 11, label: 'Nov' },
  { value: 12, label: 'Dic' },
])

// sales by category
const salesByCategory = computed(() => {
  const isDark = !!(store.theme === 'dark' || store.isDarkMode)
  return {
    chart: {
      type: 'donut',
      height: 460,
      fontFamily: 'Poppins, sans-serif',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 25,
      colors: isDark ? '#0e1726' : '#fff',
    },
    colors: isDark ? ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'] : ['#e2a03f', '#5c1ac3', '#e7515a'],
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '14px',
      markers: {
        width: 10,
        height: 10,
        offsetX: -2,
      },
      height: 50,
      offsetY: 20,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '29px',
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: '26px',
              color: isDark ? '#bfc9d4' : undefined,
              offsetY: 16,
              formatter: (val: unknown) => {
                return val
              },
            },
            total: {
              show: true,
              label: 'Total',
              color: '#888ea8',
              fontSize: '29px',
              formatter: (w: { globals: { seriesTotals: number[] } }) => {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
              },
            },
          },
        },
      },
    },
    labels: props.salesData.map(sale => sale.name),
    states: {
      hover: {
        filter: {
          type: 'none',
          value: 0.15,
        },
      },
      active: {
        filter: {
          type: 'none',
          value: 0.15,
        },
      },
    },
  }
})
</script>

<template>
  <section>
    <div class="mb-5 flex items-center justify-between">
      <div class="flex-1">
        <h5 class="text-lg font-semibold dark:text-white-light">
          Análisis de ventas
        </h5>
      </div>
      <select
        v-model="selectedOption"
        class="form-select form-select-lg flex-none w-44"
        @change="emits('handleLoadSalesPerMonth', ($event.target as HTMLSelectElement).value)"
      >
        <option
          v-for="option in options"
          :key="option.label"
          :value="option.value"
          :selected="option.value === monthRef"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div
      v-if="allSalesAreZero"
      class="text-center text-lg text-gray-500 dark:text-white-light h-[260px] flex items-center justify-center"
    >
      <h4 class="font-bold">
        No hay datos que mostrar.
      </h4>
    </div>
    <div v-else>
      <VueApexCharts
        height="460"
        :options="salesByCategory"
        :series="salesByCategorySeries"
        class="overflow-hidden rounded-lg bg-white dark:bg-black"
      >
        <div class="grid min-h-[460px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
          <span
            class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"
          />
        </div>
      </VueApexCharts>
    </div>
    <table class="table-auto w-full mb-5">
      <thead>
        <tr>
          <th class="px-4 py-2">
            Producto
          </th>
          <th class="px-4 py-2">
            Tienda
          </th>
          <th class="px-4 py-2">
            Cantidad Vendida
          </th>
          <th class="px-4 py-2">
            Total Vendido
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(sale, index) in salesData"
          :key="index"
        >
          <td class="border px-4 py-2">
            {{ sale.name }}
          </td>
          <td class="border px-4 py-2">
            {{ sale.placeName }}
          </td>
          <td class="border px-4 py-2">
            {{ sale.sales }}
          </td>
          <td class="border px-4 py-2">
            {{ useFormatterPrices(sale.sales * sale.productPrice) }}
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>
